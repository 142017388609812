<template>
  <section class="body">
    <section class="banner">
      <div class="summary">
        <h2><svg class="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 78" width="450" height="78">
          <g fill="#fff">
            <path d="M26.533 37.518l2.229-8.107H22.68l1.098-10.34h2.942l2.23-8.107h-4.311l.545-5.128h-8.003l-.604 5.128H8.688l-1.555 8.107h8.488l-.123 1.043H8.96L0 63.781h8.036l5.44-26.514-4.089 34.689 9.161-3.645 3.271-30.793h4.714z" />
            <path d="M60.279 52.365h-8.106l-1.18 3.308h-9.242l-.495-3.308h-8.019l.494 3.308H21.383l-.841 8.108h49.089l.841-8.108H59.098l1.181-3.308z" />
            <path d="M88.75 7.321h-8.163L68.149 35.085h7.744l-4.664 36.639 9.101-1.906 4.914-45.368h-4.168L88.75 7.321zM44.663 23.488l-3.177 8.107h13.322l1.183-2.425.559 2.425h8.371l-1.108-4.809h4.316l2.229-8.107h-8.414l-.308-1.336H72.42l2.229-8.107H59.767l-.442-1.915h-8.371l3.727 16.167H44.663zM37.343 15.428l-.65 1.502h-7.666l-2.229 8.107h6.391l-2.815 6.509h9.337l7.951-17.738-2.079-6.487H31.128l-2.23 8.107h8.445zM62.745 51.287l3.128-18.453H30.799l-3.127 18.453h24.211l2.221-8.108H37.689l.233-2.238h18.927l-2.253 10.346h8.149zM87.83 55.673l-.841 8.108h30.925l8.001-8.108h-17.187l2.397-23.116h16.264l.841-8.107h-16.264l1.81-17.447-8.996 2.692-1.329 14.755H87.692l-.841 8.107h15.87l-2.082 23.116H87.83z" />
            <path d="M206.89 0h-21.606l-30.595 31.001L124.094 0h-9.971l35.58 36.053L108.305 78h21.607l30.595-31.001L191.101 78h9.971l-35.58-36.053L206.89 0z" />
            <path d="M211.235 0l-6.634 6.722h45.049L203.829 78h25.241l50.143-78h-67.978zM449.26 12.162a3.12 3.12 0 0 0-2.569-1.172h-32.778l-4.002 4.056h26.804q1.314 0 1.106 1.33l-3.101 19.837h-30.474l-3.72 23.804 7.906 8.011h33.848l.624-3.992h-30.976l3.715-23.766h30.35l3.952-25.287a3.451 3.451 0 0 0-.685-2.82z" />
            <path d="M417.733 0h-21.607l-30.595 31.001L334.937 0h-9.972l35.581 36.052L319.148 78h21.606l30.595-31.001L401.944 78h9.971l-35.58-36.053L417.733 0zM248.204 78l72.394-78h-3.854l-72.393 78h3.853z" />
          </g>
        </svg>魔方咖啡车</h2>
        <p class="price">指导价 <strong><em>19.8</em>万元</strong></p>
      </div>
      <img src="../../assets/images/cafevan/banner.png" width="1260" height="830" class="img" >
    </section>

    <div class="layout">
      <div class="list">
        <div class="col">
          <div class="item">
            <h3>拥有合法路权</h3>
            <p>可上新能源绿牌</p>
          </div>
          <div class="item">
            <h3>时尚外观，彩色车身</h3>
            <p>潮流装扮易于营造休闲氛围，迎合年轻人的喜爱；</p>
          </div>
          <div class="item">
            <h3>车身窄，占地面积小</h3>
            <p>便于在人群聚集场所穿行及停驻营业；</p>
          </div>
        </div>
        <div class="col">
          <div class="item">
            <h3>货厢空间大</h3>
            <p>可容纳全套咖啡制作及其他餐饮加工设备，人可站于车内操作；</p>
          </div>
          <div class="item">
            <h3>180公里续航里程</h3>
            <p>每公里运行费用低至5分钱，运行成本低。</p>
          </div>
          <div class="item">
            <h3>自带外放电源</h3>
            <p>可供照明、氛围灯、广告牌用电，满足晚间营业需求。</p>
          </div>
        </div>
      </div>

      <div class="scene">
        <div class="hd">
          <h3>兼具<strong>运输、广告</strong>和<strong>现场加工、售卖</strong>功能，一辆车就是一家<strong>移动咖啡馆</strong></h3>
        </div>
        <div class="bd">
          <div class="col">
            <img class="img" src="../../assets/images/cafevan/pic_1.png" width="880" height="540" />
          </div>
          <div class="col">
            <div class="wrap"><img class="img" src="../../assets/images/cafevan/pic_2.png" width="340" height="265" /></div>
            <div class="wrap"><img class="img" src="../../assets/images/cafevan/pic_3.png" width="340" height="265" /></div>
          </div>
        </div>
        <div class="ft">
          <p class="address">目前在淄博、甘肃、济南等地投入使用</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CSX7",
};
</script>

<style lang="scss" scoped>
.body {
  background-color: #161618;
}

.layout {
  max-width: 1270px;
}

.banner {
  position: relative;
  margin-bottom: 250px;
  width: 100%;
  height: 850px;
  color: #fff;
  background-color: #FF3F00;

  &::after {
    position: absolute;
    left: 0;
    bottom: -45px;
    width: 50%;
    height: 56px;
    content: '';
    background-image: linear-gradient(135deg, rgba(0, 0, 0, 0) 30%, #00ffcc 30%, #00ffcc 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 80%, #00ffcc 80%, #00ffcc 100%);
    background-size: 16px 16px;
    z-index: 1;
  }

  .summary, .img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(80px, 9px);
    z-index: 5;
  }

  h2 {
    color: #fff;
    font-size: 120px;
    line-height: 160px;
    font-weight: 200;
    font-style: italic;

    .logo {
      display: block;
      margin-left: 24px;
      width: 376px;
      height: 64px;
    }
  }

  .price {
    margin: 170px 80px 0 0;
    font-size: 28px;
    line-height: 58px;
    color: #00ffcc;
    text-align: right;
    border-bottom: 18px solid #00ffcc;

    strong {
      font-size: 38px;
    }

    em {
      font-style: normal;
      font-size: 48px;
    }
  }

  .img {
    transform: translate(-840px, 200px);
    z-index: 9;
  }
}

.list {
  display: flex;
  padding: 50px 0;
  justify-content: space-between;
  color: #fff;
  font-size: 22px;
  line-height: 40px;
  counter-reset: item;

  .col {
    display: flex;
    width: 40%;
    flex-direction: column;

    &:nth-child(2n) {
      width: 52%;
    }

    .item {
      position: relative;
      margin-bottom: 50px;

      &::before, &::after {
        position: absolute;
        top: 0;
        right: 100%;
        color: #808080;
        font-size: 48px;
        line-height: 70px;
        transform: translate(-0.15em, 0.1em);
      }
      &::before {
        transform: translate(-0.7em, -0.35em);
        counter-increment: item;
        content: counter(item);
      }
      &::after {
        font-size: 50px;
        font-weight: 100;
        content: '/'
      }

      h3 {
        font-size: 44px;
        line-height: 80px;
        color: #fff;
      }
    }
  }
}

.scene {
  .hd {
    margin-bottom: 16px;
    padding: 10px;
    background-image: linear-gradient(135deg, #161618 30%, #ff3f00 30%, #ff3f00 50%, #161618 50%, #161618 80%, #ff3f00 80%, #ff3f00 100%);
    background-size: 14px 14px;

    h3 {
      padding: 20px;
      text-align: center;
      font-size: 36px;
      line-height: 46px;
      color: #ff3f00;
      background-color: #161618;
    }
  }

  .bd {
    display: flex;

    .img {
      display: block;
      width: 100%;
      height: auto;
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 88;

      & + .col {
        margin-left: 10px;
        flex: 34;
      }
    }
  }

  .ft {
    display: flex;
    justify-content: flex-end;
    
    .address {
      position: relative;
      padding: 40px 0 240px;
      color: #ff3f00;
      font-size: 36px;
      font-weight: bold;
      line-height: 68px;

      &:before {
        display: inline-block;
        margin-right: 8px;
        vertical-align: text-bottom;
        width: 55px;
        height: 70px;
        content: '';
        background: url(../../assets/images/cafevan/icon_address.png) no-repeat 50%;
        background-size: contain;
      }

      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 48px;
        content: '';
        background-image: linear-gradient(135deg, #161618 30%, #ff3f00 30%, #ff3f00 50%, #161618 50%, #161618 80%, #ff3f00 80%, #ff3f00 100%);
        background-size: 14px 14px;
      }
    }
  }
}


@media only screen and (max-width: 500px) {
  .banner {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: calc(100vh - 60px);

    &::after {
      bottom: -24px;
      height: 28px;
      background-size: 10px 10px;
    }

    .summary {
      position: static;
      transform: translate(0, -40px);
    }

    h2 {
      font-size: 60px;
      line-height: 80px;
      text-align: center;

      .logo {
        margin: 0 auto;
        width: 188px;
        height: 32px;
      }
    }

    .price {
      margin: 20px 0;
      font-size: 14px;
      line-height: 28px;
      text-align: center;
      border-bottom: 0;

      strong {
        font-size: 20px;
      }

      em {
        font-style: normal;
        font-size: 24px;
      }
    }

    .img {
      left: 0;
      width: 100%;
      height: auto;
      transform: translate(12px, 60px);
    }
  }

  .list {
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
    min-height: 100vh;
    font-size: 14px;
    line-height: 20px;

    .col {
      width: 100%;
      padding-left: 40px;

      &:nth-child(2n) {
        width: 100%;
      }

      .item {
        position: relative;
        margin-bottom: 28px;

        &::before, &::after {
          font-size: 24px;
          line-height: 36px;
        }

        h3 {
          font-size: 22px;
          line-height: 40px;
          color: #fff;
        }
      }

      
    }
  }

  .scene {
    .hd {
      margin-bottom: 12px;
      padding: 8px;
      background-size: 10px 10px;

      h3 {
        padding: 12px;
        font-size: 18px;
        line-height: 28px;
      }
    }

    .bd {
      display: block;

      .col {
        & + .col {
          margin: 8px 0 0;
          flex-direction: row;

          .wrap {
            flex: 1;

            & + .wrap {
              margin-left: 8px;
            }
          }
        }
      }
    }

    .ft {
      .address {
        padding: 20px 0 84px;
        width: 100%;
        text-align: center;
        font-size: 18px;
        line-height: 34px;

        &:before {
          margin-right: 6px;
          width: 28px;
          height: 36px;
        }

        &::after {
          left: -10px;
          width: calc(100% + 20px);
          height: 24px;
          background-size: 10px 10px;
        }
      }
    }
  }


}
</style>